@import url('https://fonts.googleapis.com/css2?family=Jaro:opsz@6..72&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background-color: rgb(0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background-color: rgb(98, 43, 226);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(105, 53, 255);
}